.t-wrapper{
    padding:  0 3rem 0 3rem;;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 7rem;
    flex-direction: column;
    gap: 2rem;
    position: relative;
}
.t-heading{
    color: #7B3F00;
    align-self: start;
    margin-bottom: 5%;
}
.t-heading>*{
    font-size: 2rem;
    font-weight: bold;
}
.t-heading span:nth-of-type(2){
    color: var(--orange);
}

.testimonial{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 2rem;
    width: 30rem;
    height: 18rem;
    border-radius: 20px;
    box-shadow:white!important;
}
.testimonial>img{
    width: 13rem;
    height: 10rem;
    border-radius: 10%;
}
.testimonial>span{
    color: #7B3F00;
}


/* slider */
.t-wrapper .swiper {
    width: 100%;
    height: 70%;
}

.t-wrapper .swiper-slide{
    display: flex;
    align-items: center;
    justify-content: center;
}

.t-wrapper .swiper-pagination-bullet-active{
    background: var(--orange);
}

@media screen and (max-width: 480px){
    .t-wrapper{
        padding: 0;
        margin-bottom: 10%;
    }
    .testimonial>img{
        width: 280px;
        height: 220px;
        padding: 10px;
    }
    .testimonial {
        justify-content: center;
    
    }
    .testimonial>span{
        color: #7B3F00;
        font-size: small;
        padding: 10px;
     
    }
  }